
function NotFound() {
	return (
		<div>
			<h1>Nonexistant Page</h1>
		</div>
	);
}

export default NotFound;
